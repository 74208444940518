<template>
    <div style="text-align: left">
      <article>
        <section>
          <div class="container mt-5">
            <div class="mb-3">
              <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/POS/menu-categories' }">Menu Categories</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: `/POS/menu-category/${$route.params.CategoryID}/${$route.params.menuCategoryName}/menu-items` }">{{ $route.params.menuCategoryName }} - Items</el-breadcrumb-item>
                <el-breadcrumb-item>Add Menu Item</el-breadcrumb-item>
              </el-breadcrumb>
            </div>
            <h1
              class="mb-4"
              style="font-size: 1.1em; font-weight: 600;"
            >Add Menu Item</h1>
            <div v-loading="isLoading">
              <el-form
                :model="itemForm"
                :rules="productRules"
                ref="itemForm"
                class="demo-itemForm2"
              >
                <div class="row">
                  <div class="col-md-6">
                    <label class="mt-3 mb-2">Item Name</label>
                    <el-form-item prop="ItemName">
                      <el-input
                        v-model="itemForm.ItemName"
                        placeholder="Item Name"
                        :disabled="isUploadingFile"
                      ></el-input>
                    </el-form-item>
                  </div>
                  <div class="col-md-6">
                    <label class="mt-3 mb-2">Item Price</label>
                    <el-form-item prop="Price">
                      <el-input
                        v-model="itemForm.Price"
                        placeholder="UGX 20000"
                        :disabled="isUploadingFile"
                      ></el-input>
                    </el-form-item>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <label class="mt-3 mb-2">Quantity Available</label>
                    <el-form-item prop="quantityAvailable">
                      <el-input
                        v-model="itemForm.quantityAvailable"
                        placeholder="100"
                        :disabled="isUploadingFile"
                      ></el-input>
                    </el-form-item>
                  </div>
                </div>
  
                <div class="row">
                  <div class="col-md-12">
                    <label class="mt-3 mb-2">Item Image</label><br />
                    <el-form-item>
                      <el-upload
                        class="upload-demo"
                        drag
                        action="''"
                        :on-change="handlePreview"
                        :on-remove="handleRemove"
                        :file-list="fileLists"
                        :auto-upload="false"
                        multiple
                      >
                        <div v-if="!isUploadingFile">
                          <i class="el-icon-upload"></i>
                          <div class="el-upload__text">
                            Drop file here or <em>click to upload</em>
                          </div>
                        </div>
                        <div v-else>
                          <el-progress
                            v-if="imageUploadingProgress != 100"
                            type="circle"
                            :color="progressColors"
                            :percentage="imageUploadingProgress"
                            :width="80"
                            :stroke-width="4"
                          ></el-progress>
                          <el-progress
                            v-else
                            type="circle"
                            :percentage="100"
                            status="success"
                            :width="80"
                            :stroke-width="4"
                          ></el-progress>
                          <div style="font-size: 0.8em; color: rgba(0, 0, 0, 0.8)">
                            {{
                            imageUploadingProgress == 100
                              ? "Uploaded"
                              : "Uploading..."
                          }}
                          </div>
                        </div>
                        <!-- <div class="el-upload__tip" slot="tip">jpg/png files with a size less than 500kb</div> -->
                      </el-upload>
                    </el-form-item>
                  </div>
                </div>
  
                <div class="row">
                  <div class="col-md-12">
                    <label class="mt-3 mb-2">Item Description</label><br />
                    <el-form-item prop="Description">
                      <el-input
                        type="textarea"
                        :rows="2"
                        v-model="itemForm.Description"
                        placeholder="Product Brief Description"
                        :disabled="isUploadingFile"
                      ></el-input>
                    </el-form-item>
                  </div>
                </div>
  
                <hr />
                <el-form-item class="text-center">
                  <div
                    v-if="isUploadingFile"
                    class="d-flex justify-content-center"
                  >
                    <ScaleOut />
                  </div>
                  <el-button
                    type="primary"
                    v-else
                    @click="addItem('itemForm')"
                  >Add Item</el-button>
                </el-form-item>
              </el-form>
  
              <div class="text-center">
                <div class="text-center">
                  <small>© 2022, All rights reserved.</small>
                </div>
                <div class="text-center">
                  <!--<small>Design by <a href="https://ovalspace.co" style="color: black;"><strong>Ovalspace</strong></a></small> -->
                </div>
              </div>
            </div>
          </div>
        </section>
      </article>
    </div>
  </template>
  
  
  <script>
  import ScaleOut from "@/components/scale-out-component.vue";
  export default {
    components: {
      ScaleOut
    },
    data() {
      return {
        addPackageModal: false,
        selectedFile: null,
        selectedSlideFile: null,
        selectedSlideFiles: [],
        isUploadingFile: false,
        isLoading: false,
        value: "",
        content: "",
        loading: false,
        loadingError: false,
        imageUploadingProgress: 0,
        progressColors: [
          { color: "#f56c6c", percentage: 25 },
          { color: "#e6a23c", percentage: 50 },
          { color: "#1989fa", percentage: 75 },
          { color: "#6f7ad3", percentage: 100 },
        ],
        itemForm: {
          ItemName: "",
          Price: "",
          quantityAvailable: "",
          Description: "",
        },
        fileLists: [],
  
        productRules: {
          ItemName: [
            {
              required: true,
              message: "Item Name is required",
              trigger: "blur",
            },
          ],
          Price: [
            {
              required: true,
              message: "Item Price is required",
              trigger: "blur",
            },
          ],
          quantityAvailable: [
            {
              required: true,
              message: "Quantity Available is required",
              trigger: "blur",
            },
          ],
          Description: [
            {
              required: true,
              message: "Brief Description is required",
              trigger: "blur",
            },
          ],
        },
  
        dialogImageUrl: "",
        dialogVisible: false,
        disabled: false,
  
        dialogImageUrlTwo: "",
        dialogVisibleTwo: false,
      };
    },
  
    methods: {
      handlePreview(file) {
        this.fileLists = [file];
        this.selectedFile = file.raw;
        console.log(this.selectedFile);
      },
      
      handleRemove() {
        this.selectedFile = null;
      },
  
      async addItem(formName) {
        this.$refs[formName].validate(async (valid) => {
          if (valid) {
            this.isUploadingFile = true;
            if (this.selectedFile == null) {
              this.$notify({
                title: "Empty",
                message: "Profile picture is empty",
                type: "warning",
              });
            }
  
            try {
              this.isUploadingFile = true;
              const formData = new FormData();
              formData.append("ItemName", this.itemForm.ItemName);
              formData.append("Price", this.itemForm.Price);
              formData.append("quantityAvailable", this.itemForm.quantityAvailable);
              formData.append( "Description", this.itemForm.Description );
              formData.append("AddedBy", this.$store.state.userId);
              formData.append("ItemImage", this.selectedFile);
              formData.append("CategoryID", `${this.$route.params.CategoryID}`);
              formData.append("CompanyID", `${this.$store.state.company.company_id}`);
              formData.append("BranchID", 1);
              let request = await this.$http.post(`menu-items`, formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
                onUploadProgress: (progressEvent) => {
                  this.imageUploadingProgress = +(
                    (progressEvent.loaded / progressEvent.total) *
                    100
                  ).toFixed(0);
                },
              });
              if (
                request.data.success &&
                request.data.message == "ITEM_CREATED_SUCCESSFULLY"
              ) {
                this.$router.push({
                  path: `/POS/menu-category/${this.$route.params.CategoryID}/${this.$route.params.menuCategoryName}/menu-items`,
                });
                return this.$notify({
                  title: "Success",
                  message: "Item Added Successfully",
                  type: "success",
                });
              } else throw "UNEXPECTED_RESPONSE";
            } catch (error) {
              if (error.message == "NetworkError") {
                this.isUploadingFile = false;
                return this.$notify({
                  title: "Connection failed",
                  message: "A network error occurred please try again",
                  type: "warning",
                });
              }
              else if (error.response && error.response.data && error.response.data.message === "File size exceeds the allowed limit") {
                this.$notify({
                    title: "File too Big",
                    message: "File size exceeds the allowed limit",
                    type: "warning",
                });
            } else if (error.response && error.response.data && error.response.data.message === "Item Name already exists") {
                this.$notify({
                    title: "Exists",
                    message: "Item Name already exists",
                    type: "warning",
                });
            } else {
                  this.$notify({
                      title: "Unable to add Menu Item",
                      message: "An unexpected Error occurred, please try again",
                      type: "error",
                  });
              }
            } finally {
              this.isUploadingFile = false;
            }
          } else {
            return false;
          }
        });
      },
    },
  };
  </script>
  
  <style scoped>
  .addBtn {
    background-color: green;
    color: white;
  }
  input[type="text"],
  select,
  textarea {
    width: 100%;
    padding: 10px 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
    background-color: #ffffff;
    font-size: 0.9em;
  }
  
  .search_add_section {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .search_by_input {
    width: 400px !important;
  }
  
  /* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
  @media (min-width: 768px) and (max-width: 1024px) {
  }
  
  /* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  }
  
  /* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
  @media (min-width: 481px) and (max-width: 767px) {
    .search_add_section {
      display: block;
    }
    .addbtn {
      width: 100%;
      margin-top: 10px;
    }
    .search_by_input {
      width: 100% !important;
    }
  }
  
  /* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
  @media (min-width: 320px) and (max-width: 480px) {
    .search_add_section {
      display: block;
    }
    .addbtn {
      width: 100%;
      margin-top: 10px;
    }
    .search_by_input {
      width: 100% !important;
    }
  }
  </style>
  
  <style>
  .safari_dialogs .el-dialog {
    width: 60%;
  }
  
  /* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
  @media (min-width: 768px) and (max-width: 1024px) {
    .safari_dialogs .el-dialog {
      width: 60%;
    }
  }
  
  /* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .safari_dialogs .el-dialog {
      width: 70%;
    }
  }
  
  /* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
  @media (min-width: 481px) and (max-width: 767px) {
    .safari_dialogs .el-dialog {
      width: 90%;
    }
  }
  
  /* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
  @media (min-width: 320px) and (max-width: 480px) {
    .safari_dialogs .el-dialog {
      width: 90%;
    }
  }
  </style>
  